/*! `Custom` Bootstrap 4 theme */
@import url('https://fonts.googleapis.com/css2?family=Lora&family=Montserrat:wght@400;600&display=swap');
$font-family-base:Montserrat;

$enable-grid-classes:false;
$primary:#FFB99B;
$secondary:#868e96;
$success:#28a745;
$danger:#dc3545;
$info:#17a2b8;
$warning:#ffc107;
$light:#f8f9fa;
$dark:#2d2e2d;
$spacer:1rem;
$border-width:1px;
$btn-border-radius:.25rem;
$headings-font-family: inherit;
$headings-font-weight: 600;
@import "bootstrap";

// Add SASS theme customizations here..
h1, .h1 {
    font-family: 'Lora';
    font-weight: 400;
}


.a {
    color: $link-color;
    text-decoration: $link-decoration;
    background-color: transparent; // Remove the gray background on active links in IE 10.
    cursor: pointer;

    @include hover() {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
    }
  }